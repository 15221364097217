@import '../../../styles//all.scss';

.imageBorder {
  border-radius: 28px;

  @include for-lg-tablet {
    border-radius: 40px;
  }
}

.graphicBroder {
}
