.c-RangeSeekBar{ 
 $height: 32px;
  $thumb-height: 32px;
  $track-height: 12px;


$upper-color: #e1e2e5;
  $lower-color: #EBB400;
  $thumb-color: #fff;
  $thumb-hover-color: #fff;
  $thumb-border-color: #191c1e;

  $upper-background: linear-gradient(to bottom, $upper-color, $upper-color)
    100% 50% / 100% $track-height no-repeat transparent;
  $lower-background: linear-gradient(to bottom, $lower-color, $lower-color)
    100% 50% / 100% $track-height no-repeat transparent;

  // Webkit cannot style progress so we fake it with a long shadow
  // on the thumb element
  @function webkit-slider-thumb-shadow($i: 1) {
    $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
    @if $i == 1 {
      @for $k from 2 through 1000 {
        $val: #{$val}, webkit-slider-thumb-shadow($k);
      }
    }
    @return $val;
  }


  input[type='range'] {
    display: block;
    appearance: none;
    width: 100%;
    margin: 0;
    height: $height;
    overflow: hidden;
    cursor: pointer;
    border-radius: 40px;
    &:focus {
      outline: none;
    }
  }

  // Webkit
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
    border-radius: 40px;
  }

  input[type='range']::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 4px solid #EBB400;
    top: 50%;
    margin-top: (-$thumb-height/2);
    box-shadow: webkit-slider-thumb-shadow();
    transition: background-color 150ms;
  }

  // Firefox
  input[type='range']::-moz-range-track,
  input[type='range']::-moz-range-progress {
    width: 100%;
    height: $height;
    background: $upper-background;
  }

  input[type='range']::-moz-range-progress {
    background: $lower-background;
  }

  input[type='range']::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  // Internet Exploder
  input[type='range']::-ms-track {
    width: 100%;
    height: $height;
    border: 0;
    // color needed to hide track marks
    color: transparent;
    background: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: $lower-background;
  }

  input[type='range']::-ms-fill-upper {
    background: $upper-background;
  }

  input[type='range']::-ms-thumb {
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $thumb-color;
    border-radius: 100%;
    border: 1px solid $upper-color;
    transition: background-color 150ms;
    // IE Edge thinks it can support -webkit prefixes
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  input[type='range']:hover,
  input[type='range']:focus {
    &::-webkit-slider-thumb {
      background-color: $thumb-hover-color;
    }
    &::-moz-range-thumb {
      background-color: $thumb-hover-color;
    }
    &::-ms-thumb {
      background-color: $thumb-hover-color;
    }
  }

}