@import '../../../styles/all.scss';

.preview-modal {
  width: 100%;
  background: transparent;
  padding: 0;
  transform: translate(-50%, -200%);
  padding: 16px;

  @include for-tablet {
    padding: 32px;
  }

  &-square {
    width: 100%;
    padding: 0;
    background: transparent;

    :global {
      .c-Modal__close-modal {
        top: 0;
        right: 0;
        transform: translate(0, -110%);
      }
    }

    //fix for the video to fit the sqaure viewport
    video {
      width: 100vw;
      height: 100vw;
      object-fit: cover;
      margin: 0 auto;

      max-width: 500px;
      max-height: 500px;
    }
  }

  &-portrait {
    width: 80%;
    top: 0;
    transform: translate(-50%, 50%);

    @include for-tablet {
      max-width: 425px;
      transform: translate(-50%, 75%);
    }
  }
}

.modal-base {
  & > div {
    background: rgba(0, 0, 0, 0.92);
  }
}
