@import '../../../styles/all.scss';

.extra-info-modal {
  animation: slide-up 0.3s linear;
  border-radius: 0;

  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 32px 24px;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :global {
    .c-Modal__close-modal {
      right: 24px;
      top: 34px;

      @include for-desktop {
        right: 32px;
        top: 38px;
      }
    }
  }

  @include for-desktop {
    padding: 32px;

    height: 90vh;
    max-height: 700px;

    border-radius: 8px;
  }
}

.modal-faq {
  &__primary {
    @include fontRegularCMC(16px, #333);
    font-weight: 600;

    margin-bottom: 12px;
  }

  &__secondary {
    @include fontRegularCMC(14px, #333);

    :global {
      .c-Text {
        &--newline {
          margin-bottom: 14px;
        }

        &--link {
          margin-bottom: 0;
          margin-top: 8px;
          color: #ca7a00;
        }
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;

    :global {
      .c-Button {
        background-color: transparent;
        border: 1px solid #2c2c2c;

        width: 100%;

        @include for-desktop {
          width: unset;
        }
      }
    }
  }
}

@keyframes slide-up {
  0% {
    transform: translate(-50%, 500%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
