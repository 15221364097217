@import '../../../styles/all';

.c-NewFooter {
  :global {
    .c-CaretDownIcon {
      transform: rotate(180deg);
      transition: all 0.2s linear;

      path {
        stroke: $black-text;
        stroke-width: 3px;

        @include for-desktop {
          stroke: #aaaaaa;
        }
      }
    }
  }
}
